<template>
  <section class="create-account">
    <!-- 标题 -->
    <h1 class="title">{{ lang.google_cancella }}</h1>
    <p class="desc">&lrm;{{ this.$commonFunc.removeSensitiveData(account) }}</p>
    <div class="divider"></div>
    <!-- 输入框 -->
    <!-- <input-normal
      class="input-t"
      @inpObj="
        (val) => {
          emailInpObj = val;
        }
      "
      :placeholder="lang.langCreateAccount_account_input_holder"
    /> -->
    <!-- 获取验证码 -->
    <el-button
      v-dbClick
      type="primary"
      @click="getCode"
      :class="['get-code-btn']"
      round
    >
      {{ lang.verifiBtn }}
    </el-button>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { googleSendVerifyCode } from "@/api/alexaLogin.js";
export default {
  name: "getCode",
  data() {
    return {
      emailInpObj: {
        // email 输入框
        inpValue: "",
        inputType: "text",
        placeholder: "Enter email",
        autoFocus: true,
      },
      account: this.$commonFunc.decrypt(this.$route.query.account),
      disabledFlag: true, // 按钮是否禁用
    };
  },
  computed: {
    ...mapState("alexaLogin", ["tabIndex", "languagePackage"]),
    lang() {
      return this.languagePackage[this.tabIndex];
    },
  },
  methods: {
    async getCode() {
      const loginKey = window.sessionStorage.getItem("loginKey");
      googleSendVerifyCode({
        verifyIdReceiver: this.account,
        type: 5,
        language: this.$commonFunc.getApiLanguage(this.tabIndex),
        appCode: this.$commonFunc.getUrlAppId(),
        loginKey,
      })
        .then((res) => {
          console.log(res, "res");
          this.$router.push(
            `/${this.$commonFunc.getUrlChannelAppID()}/verification-code${this.$commonFunc.linkUrlParams()}`
          );
        })
        .catch((err) => {
          console.log(err, "err");
          this.$toast(err?.data?.msg);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.create-account {
  padding: 60px 30px 0 30px;
  font-size: 14px;

  .title {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.9);
  }

  .desc {
    margin-top: 45px;
    margin-bottom: 13px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
  }
  .divider {
    width: 100%;
    height: 0.5px;
    background-color: #999999;
  }
  .input-t {
    // margin-top: 20px;
    height: 48px;
  }

  .get-code-btn {
    margin-top: 80px;
    width: 100%;
    height: 44px;
    border: none;
  }

  .el-disabled {
    background-color: #a0cfff;
  }

  .back-btn {
    font-size: 16px;
    color: #267aff;
    margin-top: 54px;
    text-align: center;
  }
}
</style>
